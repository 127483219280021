<template>
  <v-card height="320" class="storie-card">
    <div class="container-img">
      <img :src="baseUrl + imgUrl" alt="" class="img-generic-100" />
    </div>
    <div class="card-section pt-1">
      <v-card-text class="storia-title" v-html="displayTitolo"></v-card-text>
    </div>
    <router-link :to="route"
      ><span class="sr-only"
        >Per saperne di pi&ugrave; su {{ displayTitolo }}</span
      >
    </router-link>
  </v-card>
</template>

<script>
import { STORIE_DETAIL } from "../router/routes"
export default {
  name: "StorieCard",
  props: {
    storia: { type: Object, required: false },
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    }
  },
  computed: {
    id () {
      return this.storia?.id ?? ""
    },
    route () {
      return { name: STORIE_DETAIL.name, params: { id: this.id } }
    },
    imgUrl () {
      return this.storia?.url_relativa_img ?? ""
    },
    titolo () {
      return this.storia?.titolo ?? ""
    },
    displayTitolo () {
      return this.titolo.length < 50 ? this.titolo : this.titolo.substring(0, 45) + '...'
    },
  }
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}
.storia-title {
  font: normal normal 600 21px/30px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
}

.storie-card {
  position: relative;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
  }
}
</style>