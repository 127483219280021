<template>
  <div class="storie">
    <!-- page title -->
    <page-title></page-title>

    <!-- storie header -->
    <v-container>
      <div class="storie-header py-4" v-html="intestazione">
      </div>

      <!-- storie body -->
      <v-row>
        <v-col
          class="col-12 col-md-4"
          v-for="storia in displayedStorie"
          :key="storia.titolo"
        >
          <storie-card :storia="storia"></storie-card>
        </v-col>
      </v-row>

      <!-- show all btn -->
      <v-row class="justify-center py-16">
        <v-btn
          class="show-all-btn"
          v-if="storie.length > shownNumber"
          @click="showMore"
          color="#2AB5B9"
          >carica altre</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import StorieCard from "../components/StorieCard";
import { getStorie, getIntestazione } from "../services/api";
export default {
  name: "Storie",
  components: {
    PageTitle,
    StorieCard,
  },
  data() {
    return {
      shownNumber: 9,
      intestazione: ""
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let storiePromise = getStorie();
    let intestazionePromise = getIntestazione("STORIE");

    if (this.$store.getters["getStorieList"].length == 0) {
      try {
        let { data } = await storiePromise;

        this.$store.dispatch("setStorieList", {
          storieList: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    try {
      let { data } = await intestazionePromise;
      // decode special characters
      var txt = document.createElement("textarea");
      txt.innerHTML = data[0].intestazione;
      this.intestazione = txt.value;
    } catch (err) {
      console.log(err);
    }

    this.$emit("setLoading", false);
  },
  computed: {
    storie() {
      return this.$store.getters["getStorieList"] ?? [];
    },
    displayedStorie() {
      return this.storie.slice(0, this.shownNumber);
    },
  },
  methods: {
    showMore() {
      this.shownNumber += 3;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.storie {
  .storie-header {
    font: normal normal normal 21px/40px Open Sans;
    letter-spacing: 0px;
  }
  .show-all-btn {
    font-weight: 700;
  }
}
</style>